import { enableProdMode } from '@angular/core';
import { UtilErrorsModule } from '@sites/util-errors';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

UtilErrorsModule.init({
  environment: environment.name,
  release: environment.release,
  sentryDsn: environment.sentryDsn,
  sentryTracingSampleRate: environment.sentrySampleRate,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
