import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () =>
      import('./login/login.component').then((m) => m.LoginComponent),
    title: getTitle('Login'),
  },
  {
    path: 'token',
    loadComponent: () =>
      import('./token/token.component').then((m) => m.TokenComponent),
    title: getTitle('Token'),
  },
  {
    path: 'google',
    loadComponent: () =>
      import('./google/google.component').then((m) => m.GoogleComponent),
    title: getTitle('Google'),
  },
  {
    path: 'microsoft',
    loadComponent: () =>
      import('./microsoft/microsoft.component').then(
        (m) => m.MicrosoftComponent
      ),
    title: getTitle('Microsoft'),
  },
  {
    path: 'consent',
    loadComponent: () =>
      import('./consent/consent.component').then((m) => m.ConsentComponent),
    title: getTitle('Consent'),
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('./logout/logout.component').then((m) => m.LogoutComponent),
    title: getTitle('Logout'),
  },
  {
    path: 'error',
    loadComponent: () =>
      import('./error/error.component').then((m) => m.ErrorComponent),
    title: getTitle('Error'),
  },
];

function getTitle(name?: string): string {
  const base = 'Human Made Machine';
  if (!name) {
    return base;
  }
  return `${name} | ${base}`;
}
