import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  provideRouter,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { DataConnectModule, provideClient } from '@sites/data-connect';
import { ProviderService } from '@sites/data-hmm/hmm-identity';
import { ENVIRONMENT } from '@sites/util-environment';
import { UtilErrorsModule } from '@sites/util-errors';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    BrowserModule,
    provideRouter(
      appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      })
    ),
    importProvidersFrom(
      UtilErrorsModule.forRoot(),
      DataConnectModule.forRoot({
        baseUrl: environment.apiUrl,
      })
    ),
    provideClient(ProviderService),
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
  ],
};
